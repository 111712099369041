import React from 'react'

function RoadMap() {
  return (

<>
<section id="services" className="services section">
                {/* Section Title */}
                <div className="container section-title">
                    <h2>مراحل کار در فیکسیت</h2>
                    <p>این شرکت به شدت به سلسله مراتب کاری اهمیت می دهد. چراکه رضایت مشتری بیشترین الویت را دارد</p>
                </div>{/* End Section Title */}
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-xl-3 col-md-6 d-flex justify-content-center" >
                            <div className="service-item position-relative rastchin ">
                                <div className="icon"><i className="bi bi-activity icon" /></div>
                                <h4><span className="stretched-link">تماس با پشتیبانی</span></h4>
                                <p>میتوانید برای شروع با پشتیبانی تماس گرفته و درخواست خود را ثبت کنید</p>
                            </div>
                        </div>{/* End Service Item */}
                        <div className="col-xl-3 col-md-6 d-flex justify-content-center" >
                            <div className="service-item position-relative rastchin ">
                                <div className="icon"><i className="bi bi-bounding-box-circles icon" /></div>
                                <h4><span className="stretched-link">ثبت درخواست و مشاوره</span></h4>
                                <p>همچنین مشاورین ما به نحوه احسن کامل ترین ماوره را به طور رایگان به شما خواهند داد.</p>
                            </div>
                        </div>{/* End Service Item */}
                        <div className="col-xl-3 col-md-6 d-flex justify-content-center" >
                            <div className="service-item position-relative rastchin ">
                                <div className="icon"><i className="bi bi-calendar4-week icon" /></div>
                                <h4><span className="stretched-link">کارشناسی خدمات</span></h4>
                                <p>در ابتدا بایستی نیاز مشتری و خدمات مورد نیاز آن کارشناسی شود.</p>
                            </div>
                        </div>{/* End Service Item */}
                        <div className="col-xl-3 col-md-6 d-flex justify-content-center" >
                            <div className="service-item position-relative rastchin ">
                                <div className="icon"><i className="bi bi-broadcast icon" /></div>
                                <h4><span className="stretched-link">اعزام نیرو</span></h4>
                                <p>پس از بررسی و تخمین خسارت متخصص آن حوزه به محل اعزام می گردد.</p>
                            </div>
                        </div>{/* End Service Item */}
                    </div>
                </div>
            </section>
</>



)
}

export default RoadMap