import React, { useEffect } from 'react'

function Header() {

    useEffect(()=> {
        
    } , [])

    const helloos = () => {
        
    }
    const clicked = () => {
        document.getElementById('body').classList.toggle('mobile-nav-active')
        document.getElementById('list-x').classList.toggle('bi-list')
        document.getElementById('list-x').classList.toggle('bi-x')
    }

    const listUpServices = () => {
        document.getElementById('listUpServices').classList.toggle('active')
        document.getElementById('ullistUpServices').classList.toggle('dropdown-active')
    }
    const listDown1Services = () => {
        document.getElementById('listDown1Services').classList.toggle('active')
        document.getElementById('ullistDown1Services').classList.toggle('dropdown-active')
    }
    const listDown2Services = () => {
        document.getElementById('listDown2Services').classList.toggle('active')
        document.getElementById('ullistDown2Services').classList.toggle('dropdown-active')
    }
    const listDown3Services = () => {
        document.getElementById('listDown3Services').classList.toggle('active')
        document.getElementById('ullistDown3Services').classList.toggle('dropdown-active')
    }
    const listUpProduct = () => {
        document.getElementById('listUpProduct').classList.toggle('active')
        document.getElementById('ullistUpProduct').classList.toggle('dropdown-active')
    }
    const listDown1Product = () => {
        document.getElementById('listDown1Product').classList.toggle('active')
        document.getElementById('ullistDown1Product').classList.toggle('dropdown-active')
    }
    const listDown2Product = () => {
        document.getElementById('listDown2Product').classList.toggle('active')
        document.getElementById('ullistDown2Product').classList.toggle('dropdown-active')
    }
    


    return (
        <>


            <header id="header" className="header d-flex align-items-center fixed-top">
                <div className="container-fluid container-xl position-relative d-flex align-items-center">
                    <span className="logo d-flex align-items-center me-auto">
                        
                        <h1 className="sitename">فیکسیت</h1>
                    </span>
                    <nav id="navmenu" className="navmenu">
                        <ul>
                            <li><a href="#hero" className>خانه</a></li>
                            <li className="dropdown"><a id='listUpServices' href="#services"><span>خدمات</span> <i className="bi bi-chevron-down toggle-dropdown" onClick={listUpServices} /></a>
                                <ul id='ullistUpServices'>
                                    
                                    <li className="dropdown"><a id='listDown1Services' href="#"><span>فروش</span> <i className="bi bi-chevron-down toggle-dropdown" onClick={listDown1Services}/></a>
                                        <ul id='ullistDown1Services'>
                                            <li><a href="#">کولر گازی</a></li>
                                            <li><a href="#">پکیج</a></li>
                                            <li><a href="#">لوازم آشپزخانه</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a id='listDown2Services' href="#"><span>تعمیر</span> <i className="bi bi-chevron-down toggle-dropdown" onClick={listDown2Services}/></a>
                                        <ul id='ullistDown2Services'>
                                            <li><a href="#">کولر گازی</a></li>
                                            <li><a href="#">پکیج</a></li>
                                            <li><a href="#">لوازم آشپزخانه</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a  id='listDown3Services' href="#"><span>سرویس</span> <i className="bi bi-chevron-down toggle-dropdown" onClick={listDown3Services}/></a>
                                    <ul id='ullistDown3Services'>
                                            <li><a href="#">کولر گازی</a></li>
                                            <li><a href="#">پکیج</a></li>
                                            <li><a href="#">لوازم آشپزخانه</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="#">گارانتی</a></li>
                                </ul>
                            </li>
                            <li className="dropdown"><a id='listUpProduct' href="#products"><span>محصولات</span> <i className="bi bi-chevron-down toggle-dropdown" onClick={listUpProduct}/></a>
                                <ul id='ullistUpProduct'>
                                    <li className="dropdown"><a  id='listDown1Product' href="#"><span>نو</span> <i className="bi bi-chevron-down toggle-dropdown" onClick={listDown1Product}/></a>
                                        <ul id='ullistDown1Product'>
                                            <li><a href="#">کولر گازی</a></li>
                                            <li><a href="#">پکیج</a></li>
                                            <li><a href="#">لوازم آشپزخانه</a></li>
                                            <li><a href="#">کولر گازی</a></li>
                                            <li><a href="#">پکیج</a></li>
                                            <li><a href="#">لوازم آشپزخانه</a></li>
                                            <li><a href="#">کولر گازی</a></li>
                                            <li><a href="#">پکیج</a></li>
                                            <li><a href="#">لوازم آشپزخانه</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a id='listDown2Product' href="#"><span>استوک</span> <i className="bi bi-chevron-down toggle-dropdown" onClick={listDown2Product}/></a>
                                        <ul id='ullistDown2Product'>
                                            <li><a href="#">کولر گازی</a></li>
                                            <li><a href="#">پکیج</a></li>
                                            <li><a href="#">لوازم آشپزخانه</a></li>
                                            <li><a href="#">کولر گازی</a></li>
                                            <li><a href="#">پکیج</a></li>
                                            <li><a href="#">لوازم آشپزخانه</a></li>
                                            <li><a href="#">کولر گازی</a></li>
                                            <li><a href="#">پکیج</a></li>
                                            <li><a href="#">لوازم آشپزخانه</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#contact">پشتیبان</a></li>
                            <li><a href="#footer">درباره فیکسیت</a></li>
                        </ul>
                        <i className="mobile-nav-toggle d-xl-none bi bi-list" id='list-x' onClick={clicked} />
                    </nav>
                    {/* <div className='serach-box-div btn-getstarted' >
                        <input type='text' className='search-box-input' />
                        <div className='search-box-icon'>
                            =
                        </div>
                    </div> */}
                </div>
            </header>








        </>
    )
}

export default Header