import React from 'react'

function Introduction() {
    return (
        <>

            <section id="hero" className="hero section">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-end">
                            <h1>فیکسیت; مطمئن و به صرفه برای همه</h1>
                            <p>با خیالی آسوده، خرید و تعمیر لوازم خود را به ما بسپارید</p>
                            
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 hero-img">
                        <img src="assets/img/fixit.png" className="img-fluid animated" />
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default Introduction