import React from 'react';
import image from '../../../Images/ff.jpg'

function Footer() {
    return (
        <>


            <footer id="footer" className="footer">
                <div className="footer-newsletter">
                    <div className="container">
                        <div className="row justify-content-center align-items-center text-center">
                            <div className="col-xl-6 about-fixit">
                                <div className='brand-div' >
                                        <h2>شرکت فیکسیت</h2>
                                    </div>
                                <p> دیگر نگران تهیه یا اعنیر لوازم خانگی خود نباشید
                                <br /> 
                                شرکت خدمات محور فیکسیت در حوزه فروش، تعمیر و همچنین مشاوره و گارارنتی محصولاتی همچون یخچال، کولر گازی، پکیج و... مشغول به فعالیت گسترده ای است و تمام تلاش خود را برای جلب رضایت پشتری های خود انجام داده است.</p>
                            </div>
                            <div className="col-lg-8 col-xl-5">
                                <h4>با جمع فیکسیتی ها بپیوندید</h4>
                                <p>با وارد کردن شماره تلفن یا آدرس اینترنتی برای پیوستن به جمع ما آمادگی خود را اعلام کنید</p>
                                <form action="forms/newsletter.php" method="post" className="php-email-form">
                                    <div className="newsletter-form"><input type="email" name="email" /><input type="submit" value={'به ما بپیوند'} /></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container footer-top">
                    <div className="row gy-4 flex-row-reverse">
                        <div className="col-lg-4 col-md-6 footer-about text-end">
                       
                                <h3 className="sitename">فیکسیت</h3>
                          
                            <div className="footer-contact pt-3">
                                <p>تهرانپارس، حکیمیه</p>
                                <p>ایران، تهران، شرق</p>
                                <p className="mt-3"><strong>شماره تماس:</strong> <span>+1 5589 55488 55</span></p>
                                <p><strong>نشانی اینترنتی:</strong> <span>info@example.com</span></p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 footer-links text-end">
                            <h4>دسترسی سریع</h4>
                            <ul>
                                <li><i className="bi bi-chevron-left" /> <a href="#">خانه</a></li>
                                <li><i className="bi bi-chevron-left" /> <a href="#">خدمات</a></li>
                                <li><i className="bi bi-chevron-left" /> <a href="#">محصولات</a></li>
                                <li><i className="bi bi-chevron-left" /> <a href="#">پشتیبانی</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-3 footer-links text-end">
                            <h4>خدمات ما</h4>
                            <ul>
                                <li><i className="bi bi-chevron-left" /> <a href="#">تعمیر کولر گازی</a></li>
                                <li><i className="bi bi-chevron-left" /> <a href="#">فروش پکیج</a></li>
                                <li><i className="bi bi-chevron-left" /> <a href="#">تعمیر یخچال و فریزر</a></li>
                                <li><i className="bi bi-chevron-left" /> <a href="#">سرویس لوازم خانگی</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-12 text-end">
                            <h4>ما را در شبکه های اجتماعی دنبال کنید</h4>
                            <div className="social-links d-flex justify-content-end">
                                <a href><i className="bi bi-twitter-x" /></a>
                                <a href><i className="bi bi-facebook" /></a>
                                <a href><i className="bi bi-instagram" /></a>
                                <a href><i className="bi bi-linkedin" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container copyright text-center mt-4">
                </div>
            </footer>






        </>

    )
}

export default Footer