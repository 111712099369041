import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer'
import Contact from './components/Contact';
import About from './components/About';
import Introduction from './components/Introduction';
import Services from './components/Services'
import Products from './components/Products';
import RoadMap from './components/RoadMap';
import '../../styles/style.css'

function Landing() {
    return (
        <>

            <Header />

            <Introduction />

            <Services />

            <Products />

            <RoadMap />
            {/* <About /> */}

            <Contact />

            <Footer />

        </>
    )
}

export default Landing