import logo from './logo.svg';
import './App.css';
import Landing from './pages/landing/Main';
import About from './pages/landing/components/About';
import Header from './pages/landing/components/Header';
import Slider from './pages/landing/Tools/Slider';

function App() {
  return (
    <>
      <Landing />

    {/* <Slider /> */}

    </>

  );
}

export default App;
