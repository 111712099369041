import React from 'react'
import imgsupp from '../../../Images/customer-service.png'
function Contact() {
    return (

        <>

            <section id="contact" className="contact section">
                {/* Section Title */}
                <div className="container section-title" >
                    <h2>پشتیبانی</h2>
                    <p>می توانید همین حالا با ثبت درخواست و تماس با مشاورین از خدمات ما بهره مند شوید</p>
                </div>{/* End Section Title */}
                <div className="container"  >
                    <div className="row gy-4">
                        <div className="col-lg-5">
                            <div className="info-wrap">
                                <div className="info-item d-flex contract-rastchin"  >
                                    <i className="bi bi-geo-alt flex-shrink-0" />
                                    <div>
                                        <h3>آدرس</h3>
                                        <p>تهران، تهرانپارس غربی، حکیمیه</p>
                                    </div>
                                </div>{/* End Info Item */}
                                <div className="info-item d-flex contract-rastchin"  >
                                    <i className="bi bi-telephone flex-shrink-0" />
                                    <div>
                                        <h3>شماره تماس</h3>
                                        <p>+98 96 626 00 85</p>
                                    </div>
                                </div>{/* End Info Item */}
                                <div className="info-item d-flex contract-rastchin"  >
                                    <i className="bi bi-envelope flex-shrink-0" />
                                    <div>
                                        <h3>نشانی اینترنتی</h3>
                                        <p>Amirreza.khs70@gmail.com</p>
                                    </div>
                                </div>{/* End Info Item */}
                                    <div className='support-guy' >
                                        <img src={imgsupp} style={{width : '60%' , height : 'auto'}} />
                                    </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <form action="#" method="post" className="php-email-form"  >
                                <div className="row gy-4 text-end">
                                    <div className="col-md-6">
                                        <label htmlFor="name-field" className="pb-2">نام</label>
                                        <input type="text" name="name" id="name-field" className="form-control contract-rastchin" required />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="email-field" className="pb-2">نام خانوادگی</label>
                                        <input type="email" className="form-control contract-rastchin" name="email" id="email-field" required />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="subject-field" className="pb-2">درخواست</label>
                                        <input type="text" className="form-control contract-rastchin" name="subject" id="subject-field" required />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="message-field" className="pb-2">توضیحات (غیر الزامی)</label>
                                        <textarea className="form-control contract-rastchin" name="message" rows={10} id="message-field" required defaultValue={""} />
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <button type="submit">ثبت درخواست</button>
                                    </div>
                                </div>
                            </form>
                        </div>{/* End Contact Form */}
                    </div>
                </div>
            </section>









        </>
    )
}

export default Contact