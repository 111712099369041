import React from 'react'
import Slider from '../Tools/Slider'

function Products() {
    return (

        <>

            <section id="products" className="testimonials section">
                {/* Section Title */}
                <div className="container section-title">
                    <h2>محصولات</h2>
                    <p>محصولاتی که فیکست درکنار فروش به شما امکان نصب و گارانتی را نیز می دهد</p>
                </div>{/* End Section Title */}
                <div className="container" >
                    <div className="product-slides">
                        <Slider />
                    </div>
                </div>
            </section>



        </>
    )
}

export default Products