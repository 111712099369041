import React from 'react' ;
import service1 from '../../../Images/shopping-online.png' ;
import service2 from '../../../Images/service.png' ;
import service3 from '../../../Images/worker.png' ;
import service4 from '../../../Images/teamwork.png' ;

function Services() {
    return (
        <>



            <section id="services" className="services section">
                {/* Section Title */}
                <div className="container section-title">
                    <h2>خدمات</h2>
                    <p>در مجموعه تمامی خدمات لوازم برقی صورت می  گیرد. همچنین محصولات را گارانتی نیز می کند</p>
                </div>{/* End Section Title */}
                <div className="container">
                    <div className="row gy-4 sevices-row">
                        <div className="col-xl-3 col-md-6 d-flex" >
                            <div className="service-item position-relative services-box flip-card">
                                <div className="icon icon-service">
                                    <img src={service1} className='image-service-box' />
                                </div>
                                
                                <h4><span className="stretched-link">خرید لوازم برقی</span></h4>
                                {/* <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi</p> */}
                            </div>
                        </div>{/* End Service Item */}
                        <div className="col-xl-3 col-md-6 d-flex" >
                            <div className="service-item position-relative services-box flip-card">
                                <div className="icon icon-service">
                                    <img src={service2} className='image-service-box' />
                                </div>
                                
                                <h4><span className="stretched-link">تعمیر لوازم برقی</span></h4>
                                {/* <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p> */}
                            </div>
                        </div>{/* End Service Item */}
                        <div className="col-xl-3 col-md-6 d-flex" >
                            <div className="service-item position-relative services-box flip-card">
                                <div className="icon icon-service">
                                    <img src={service3} className='image-service-box' />
                                </div>
                                
                                <h4><span className="stretched-link">سرویس سالیانه</span></h4>
                                {/* <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p> */}
                            </div>
                        </div>{/* End Service Item */}
                        <div className="col-xl-3 col-md-6 d-flex" >
                            <div className="service-item position-relative services-box flip-card">
                                <div className="icon icon-service">
                                    <img src={service4} className='image-service-box' />
                                </div>
                                
                                <h4><span className="stretched-link">گارانتی محصولات</span></h4>
                                {/* <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis</p> */}
                            </div>
                        </div>{/* End Service Item */}
                    </div>
                </div>
            </section>







        </>



    )
}

export default Services