import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
// import 'swiper/swiper-bundle.min.css';
import image1 from '../../../Images/products/de (4).jpg'
import image2 from '../../../Images/products/de (1).jpg'
import image3 from '../../../Images/products/de (2).jpg'
import image4 from '../../../Images/products/de (9).jpg'
import image5 from '../../../Images/products/de (8).jpg'
import image6 from '../../../Images/products/de (7).jpg'
import image7 from '../../../Images/products/de (3).jpg'
import image8 from '../../../Images/products/de (4).jpg'
import image9 from '../../../Images/products/de (5).jpg'
import image10 from '../../../Images/products/de (6).jpg'
import { Navigation, Pagination, Scrollbar, A11y, EffectCube } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-cube';


function Slider() {

    useEffect(() => {
        console.log(slides)
    }, [])
    const slides = [{
        title: '1',
        image: image1
    },
    {
        title: '2',
        image: image2
    },
    {
        title: '3',
        image: image3
    },
    {
        title: '4',
        image: image4
    },
    {
        title: '5',
        image: image5
    },
    {
        title: '6',
        image: image6
    },
    {
        title: '7',
        image: image7
    },
    {
        title: '8',
        image: image8
    },
    {
        title: '9',
        image: image9
    },
    {
        title: '10',
        image: image10
    }]
    return (
        <>

            <div className='main-swipers' style={{ height: '100%', width: '100%' }}>
                <Swiper
                style={{width : '78%'}}
                    spaceBetween={10}
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    slidesPerView={2}
                    loop={true}
                    navigation = {{clickable: true}}
                    pagination={{
                        dynamicBullets: true,
                        clickable: true
                    }}
                    breakpoints={{
                        750: {
                            slidesPerView: 2,
                        },
                        950: {
                            slidesPerView: 3,
                        },
                        1200: {
                            slidesPerView: 4,
                        },

                    }}
                // effect='cube'
                // cubeEffect={{
                //     slideShadows: false,
                //     shadow: false,
                // }}
                // modules={[Navigation, EffectCube]}
                >
                    {
                        slides.map((slide) => (
                            <SwiperSlide key={slide.image} className='slider-swiper-card'>
                                <img src={slide.image} alt={slide.title} className='image-slider-items' />
                            </SwiperSlide>
                        ))
                    }
                </Swiper >

            </div >
        </>
    )
}

export default Slider